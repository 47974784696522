/* eslint-disable */
export const apps = [
    {
        id         : 'a1ae91d3-e2cb-459b-9be9-a184694f548b',
        label      : 'Workspaces',
        description: 'Espaces de travail',
        icon       : 'mat_outline:auto_awesome_mosaic',
        link       : '/workspaces',
        useRouter  : true
    },
    {
        id         : '989ce876-c177-4d71-a749-1953c477f825',
        label      : 'Mon agenda',
        description: 'Agenda collaboratif',
        icon       : 'heroicons_outline:calendar',
        link       : '/scheduler',
        useRouter  : true
    },
    {
        id         : '2496f42e-2f25-4e34-83d5-3ff9568fd984',
        label      : 'Meeting',
        description: 'Visio conférence',
        icon       : 'mat_outline:video_camera_front',
        link       : '/workspaces',
        useRouter  : true
    },
    {
        id         : '3c48e75e-2ae7-4b73-938a-12dc655be28b',
        label      : 'Boîte de réception',
        description: 'Messaging',
        icon       : 'heroicons_outline:mail',
        link       : '/mailbox',
        useRouter  : true
    },
    {
        id         : '2daac375-a2f7-4393-b4d7-ce6061628b66',
        label      : 'Notes',
        description: 'Gestion des notes',
        icon       : 'heroicons_outline:pencil-alt',
        link       : '/notes',
        useRouter  : true
    },
    {
        id         : '56a0a561-17e7-40b3-bd75-0b6cef230b7e',
        label      : 'Mon travail',
        description: 'Activité de mes tâches',
        icon       : 'heroicons_outline:check-circle',
        link       : '/tasks',
        useRouter  : true
    },
    {
        id         : 'f5daf93e-b6f3-4199-8a0c-b951e92a6cb8',
        label      : 'Équipes',
        description: 'Liste des contacts',
        icon       : 'heroicons_outline:user-group',
        link       : '/contacts',
        useRouter  : true
    },
    {
        id         : '0a240ab8-e19d-4503-bf68-20013030d526',
        label      : 'Actualiser',
        description: 'Actualiser la page',
        icon       : 'heroicons_outline:refresh',
        link       : '/',
        useRouter  : false
    }
];
