<!-- Button -->
<button
    mat-icon-button [matTooltip]="user.name" matTooltipPosition="below"
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <ngx-avatar *ngIf="showAvatar && user.avatar"
                    class="w-10 h-10 rounded-full"
                    [name]="user.name" [src]="!showAvatar ? user.avatar : ''" size="36"></ngx-avatar>
        <mat-icon
            *ngIf="!showAvatar || !user.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full border"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"
        ></span>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Connecté en tant que</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profil</span>
    </button>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Réglages</span>
    </button>
    <button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Statut</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Déconnexion</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>En ligne</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Absent</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Occupé</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
