import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { workspaces as workspacesData, projects as projectsData, members as membersData } from 'app/mock-api/main/portal/workspaces/data';

@Injectable({
    providedIn: 'root'
})
export class WorkspacesMockApi
{
    // Private
    private _workspaces: any[] = workspacesData;
    private _projects: any[] = projectsData;
    private _members: any[] = membersData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Workspaces - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/portal/workspaces')
            .reply(({request}) => {

                // Clone the projects
                let projects = cloneDeep(this._projects);

                // Go through the boards and inject the members
                projects = projects.map(project => ({
                    ...project,
                    members: project.members.map(boardMember => this._members.find(member => boardMember === member.id))
                }));

                // Clone the workspaces
                const workspaces = cloneDeep(this._workspaces);

                // Add the projects to the workspaces
                workspaces.forEach((workspace) => {
                    // Get the projects
                    const workspaceProjects = projects.filter(project => project.workspaceId === workspace.id);
                    // Add the projects to the workspace
                    workspace.projects = workspaceProjects;
                });

                return [
                    200,
                    workspaces
                ];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Board - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/portal/workspace')
            .reply(({request}) => {

                // Get the id
                const id = request.params.get('id');

                // Find the board
                const workspace = this._workspaces.find(item => item.id === id);

                // Add the projects to the workspace
                workspace.projects = this._projects.filter(project => project.workspaceId === workspace.id);

                return [
                    200,
                    cloneDeep(workspace)
                ];
            });
    }
}
