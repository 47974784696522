<!-- Shortcuts toggle -->
<button
    mat-icon-button [matTooltip]="'Applications'" [matTooltipPosition]="'below'"
    (click)="openPanel()"
    #shortcutsOrigin>
    <mat-icon [svgIcon]="'mat_outline:apps'"></mat-icon>
</button>

<!-- Shortcuts panel -->
<ng-template #shortcutsPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-120 sm:w-120 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-2 pr-4 pl-6 text-on-primary apps">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="flex items-center text-2xl font-bold leading-10 ">
                <span class="">Apps</span>
            </div>

        </div>
        <div class="relative flex flex-col flex-auto sm:max-h-100 -mb-px overflow-y-auto bg-card bg-accent-300">

            <!-- View mode -->
            <ng-container *ngIf="mode === 'view'">

                <!-- Shortcuts -->
                <div class="grid grid-cols-2 grid-flow-row px-0 py-1 pr-1">
                    <!-- Shortcut -->

                    <ng-container *ngFor="let shortcut of shortcuts; trackBy: trackByFn">
                        <fuse-card class="relative group flex flex-col overflow-hidden bg-card items-center mx-2 even:mx-0 even:mx-0 my-1 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">
                            <div class="">

                                <!-- Normal links -->
                                <a
                                    class="flex flex-col items-center justify-center w-full h-full py-6 no-underline"
                                    *ngIf="!shortcut.useRouter"
                                    [ngClass]="{'pointer-events-none': mode === 'modify'}"
                                    [href]="shortcut.link">
                                    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                                </a>
                                <!-- Router links -->
                                <a
                                    class="flex flex-col items-center justify-center w-full h-full py-6 no-underline"
                                    *ngIf="shortcut.useRouter"
                                    [ngClass]="{'pointer-events-none': mode === 'modify'}"
                                    [routerLink]="shortcut.link">
                                    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                                </a>
                                <!-- Link content template -->
                                <ng-template #linkContent>
                                    <div class="relative flex shrink-0 items-center justify-center w-12 h-12 mb-3 rounded-full bg-gray-100 dark:bg-gray-700">
                                        <mat-icon
                                            class="absolute opacity-0 group-hover:opacity-100 z-20 icon-size-5"
                                            *ngIf="mode === 'modify'"
                                            [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                                        <mat-icon
                                            class="z-10"
                                            [ngClass]="{'group-hover:opacity-0': mode === 'modify'}"
                                            [svgIcon]="shortcut.icon"></mat-icon>
                                    </div>
                                    <div class="font-medium text-center">{{shortcut.label}}</div>
                                    <div class="text-md text-center text-secondary">{{shortcut.description}}</div>
                                </ng-template>
                            </div>
                        </fuse-card>
                    </ng-container>
                </div>

                <!-- No shortcuts -->
                <ng-container *ngIf="!shortcuts || !shortcuts.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                        <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                            <mat-icon
                                class="text-primary-500-700"
                                [svgIcon]="'mat_outline:app_registration'"></mat-icon>
                        </div>
                        <div class="mt-5 text-2xl font-semibold tracking-tight">Pas d'application</div>
                        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Lorsque vous avez des applications, ils apparaîtront ici.</div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>
