import * as moment from 'moment';

export class BoardFakeDb
{
    public static boards = [
        {
            'id'      : '1',
            'name'    : 'ACME Frontend Application',
            'reference': 'REF001',
            'settings': {
                'color'          : 'fuse-white',
                'subscribed'     : false,
                'coverImages'    : true
            },
            'lists'   : [
                {
                    'id'     : '56027cf5a2ca3839a5d36103',
                    'name'   : 'Design',
                    'color'  : 'grey-300',
                    'idCards': [
                        '5603a2a3cab0c8300f6096b3',
                        '5603a2a3cab0c8300f6096b4'
                    ]
                },
                {
                    'id'     : '56127cf2a2ca3539g7d36103',
                    'name'   : 'Development',
                    'color'  : 'blue-grey-100',
                    'idCards': [
                        '2837273da9b93dd84243s0f9',
                        '5787b7e4740c57bf0dffd5b6',
                        '5637273da9b93bb84743a0f9',
                        '5637273da9b93bb84743a100'
                    ]
                },
                {
                    'id'     : 'faf244627326f1249525763d',
                    'name'   : 'Upcoming Features',
                    'color'  : 'orange-100',
                    'idCards': [
                        'd9005a4b89ed2aadca48a6ad',
                        'f6b9d7a9247e5d794a081927',
                        'f6b9d7a9247e5d794a081928',
                        'f6b9d7a9247e5d794a081929',
                        'f6b9d7a9247e5d794a081930'
                    ]
                },
                {
                    'id'     : 'fbf244627326f1249525763d',
                    'name'   : 'Known Bugs',
                    'color'  : 'purple-100',
                    'idCards': [
                        'f6b9d7a9247e5d794a081931',
                        'f6b9d7a9247e5d794a081932',
                        'f6b9d7a9247e5d794a081933'
                    ]
                }
            ],
            'cards'   : [
                {
                    'id'               : '2837273da9b93dd84243s0f9',
                    'name'             : 'Update generators',
                    'description'      : 'Current generator doesn\'t support Node.js 6 and above.',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '56027c1930450d8bf7b10758'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c6'
                    ],
                    'attachments'      : [],
                    'status'           : 'in progress',
                    'priority'         : 'none',
                    'subscribed'       : false,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'AngularCLI could be a nice alternative.',
                            'time'    : 'now'
                        }
                    ],
                    'activities'       : []
                },
                {
                    'id'               : '5603a2a3cab0c8300f6096b3',
                    'name'             : 'Change background colors',
                    'description'      : '',
                    'idAttachmentCover': '67027cahbe3b52ecf2dc631c',
                    'idMembers'        : [
                        '56027c1930450d8bf7b10758'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c6',
                        '26022e4129ad3a5sc28b36c3'
                    ],
                    'attachments'      : [
                        {
                            'id'  : '67027cahbe3b52ecf2dc631c',
                            'name': 'mail.jpg',
                            'src' : 'assets/images/cards/01-320x200.jpg',
                            'time': 'Nov 3 at 15:22AM',
                            'type': 'image'
                        },
                        {
                            'id'  : '56027cfcbe1b72ecf1fc452a',
                            'name': 'calendar.jpg',
                            'src' : 'assets/images/cards/02-320x200.jpg',
                            'time': 'Nov 1 at 12:34PM',
                            'type': 'image'
                        }
                    ],
                    'status'           : 'in progress',
                    'priority'         : 'none',
                    'subscribed'       : true,
                    'checklists'       : [
                        {
                            'id'               : '63021cfdbe1x72wcf1fc451v',
                            'name'             : 'Checklist',
                            'checkItemsChecked': 1,
                            'checkItems'       : [
                                {
                                    'name'   : 'Implement a calendar library',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Replace event colors with Material Design colors',
                                    'checked': true
                                },
                                {
                                    'name'   : 'Replace icons with Material Design icons',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Use moment.js',
                                    'checked': false
                                }
                            ]
                        },
                        {
                            'name'             : 'Checklist 2',
                            'id'               : '74031cfdbe1x72wcz1dc166z',
                            'checkItemsChecked': 1,
                            'checkItems'       : [
                                {
                                    'name'   : 'Replace event colors with Material Design colors',
                                    'checked': true
                                },
                                {
                                    'name'   : 'Replace icons with Material Design icons',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Use moment.js',
                                    'checked': false
                                }
                            ]
                        }
                    ],
                    'checkItems'       : 7,
                    'checkItemsChecked': 2,
                    'comments'         : [
                        {
                            'idMember': '56027c1930450d8bf7b10758',
                            'message' : 'We should be able to add moment.js without any problems',
                            'time'    : '12 mins. ago'
                        },
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'I added a link for a page that might help us deciding the colors',
                            'time'    : '30 mins. ago'
                        }
                    ],
                    'activities'       : [
                        {
                            'idMember': '56027c1930450d8bf7b10758',
                            'message' : 'added a comment',
                            'time'    : moment().subtract(25, 'minutes').toISOString() // 25 minutes ago
                        },
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'added a comment',
                            'time'    : moment().subtract(50, 'minutes').toISOString() // 50 minutes ago
                        },
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'attached a link',
                            'time'    : moment().subtract(3, 'hours').toISOString() // 3 hours ago
                        }
                    ]
                },
                {
                    'id'               : '5637273da9b93bb84743a0f9',
                    'name'             : 'Fix splash screen bugs',
                    'description'      : '',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '56027c1930450d8bf7b10758'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c3'
                    ],
                    'attachments'      : [],
                    'status'           : 'open',
                    'priority'         : 'none',
                    'subscribed'       : true,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [],
                    'activities'       : []
                },
                {
                    'id'               : 'd9005a4b89ed2aadca48a6ad',
                    'name'             : 'Add alternative authentication pages',
                    'description'      : '',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '56027c1930450d8bf7b10758'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c3',
                        '26022e4129ad3a5sc28b36cd'
                    ],
                    'attachments'      : [],
                    'status'           : 'open',
                    'priority'         : 'none',
                    'subscribed'       : false,
                    'checklists'       : [
                        {
                            'id'               : 'dbfb.99bd0ad37dabc.e05046f0c824d.18f26bb524c96.78bebc8488634.240c0ee6a5e45.4cb872965',
                            'name'             : 'Pages',
                            'checkItemsChecked': 2,
                            'checkItems'       : [
                                {
                                    'name'   : 'Login',
                                    'checked': true
                                },
                                {
                                    'name'   : 'Register',
                                    'checked': true
                                },
                                {
                                    'name'   : 'Lost Password',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Recover Password',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Activate Account',
                                    'checked': false
                                }
                            ]
                        }
                    ],
                    'checkItems'       : 5,
                    'checkItemsChecked': 2,
                    'comments'         : [],
                    'activities'       : []
                },
                {
                    'id'               : '5787b7e4740c57bf0dffd5b6',
                    'name'             : 'Fix the console',
                    'description'      : 'We need to fix the console asap!',
                    'idAttachmentCover': '',
                    'idMembers'        : [],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36cd'
                    ],
                    'attachments'      : [],
                    'status'           : 'in progress',
                    'priority'         : 'none',
                    'subscribed'       : true,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'I\'m on it!',
                            'time'    : 'now'
                        }
                    ],
                    'activities'       : []
                },
                {
                    'id'               : 'f6b9d7a9247e5d794a081927',
                    'name'             : 'New media player',
                    'description'      : '',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '56027c1930450d8bf7b10758',
                        '26027s1930450d8bf7b10828'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36cd',
                        '26022e4129ad3a5sc28b36c3'
                    ],
                    'attachments'      : [],
                    'status'           : 'open',
                    'priority'         : 'none',
                    'subscribed'       : false,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [],
                    'activities'       : []
                },
                {
                    'id'               : 'f6b9d7a9247e5d794a081931',
                    'name'             : 'Memory Leak',
                    'description'      : '',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '26027s1930450d8bf7b10828'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c3',
                        '26022e4129ad3a5sc28b36cd'
                    ],
                    'attachments'      : [],
                    'status'           : 'review',
                    'priority'         : 'none',
                    'subscribed'       : false,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [],
                    'activities'       : []
                },
                {
                    'id'               : 'f6b9d7a9247e5d794a081932',
                    'name'             : 'Broken toolbar on profile page',
                    'description'      : '',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '26027s1930450d8bf7b10828'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36cd'
                    ],
                    'attachments'      : [],
                    'status'           : 'open',
                    'priority'         : 'none',
                    'subscribed'       : false,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'This should be a medium priority bug, shouldn\'t it?',
                            'time'    : 'now'
                        }
                    ],
                    'activities'       : []
                },
                {
                    'id'               : 'f6b9d7a9247e5d794a081933',
                    'name'             : 'Button hover style',
                    'description'      : 'If there are 3 or more buttons in certain page, weird flashing happens when you hover over the red ones.',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '26027s1930450d8bf7b10828'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36cd',
                        '26022e4129ad3a5sc28b36c3'
                    ],
                    'attachments'      : [],
                    'status'           : 'closed',
                    'priority'         : 'none',
                    'subscribed'       : true,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [],
                    'activities'       : []
                },
                {
                    'id'               : 'f6b9d7a9247e5d794a081928',
                    'name'             : 'New header designs',
                    'description'      : '',
                    'idAttachmentCover': '12027cafbe3b52ecf2ef632c',
                    'idMembers'        : [],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c3',
                        '26022e4129ad3a5sc28b36c6'
                    ],
                    'attachments'      : [
                        {
                            'id'  : '12027cafbe3b52ecf2ef632c',
                            'name': 'header-.jpg',
                            'src' : 'assets/images/cards/01-320x200.jpg',
                            'time': 'Nov 3 at 15:22AM',
                            'type': 'image'
                        },
                        {
                            'id'  : '55027ced1e1a12ecf1fced2a',
                            'name': 'header-2.jpg',
                            'src' : 'assets/images/cards/01-320x200.jpg',
                            'time': 'Nov 1 at 12:34PM',
                            'type': 'image'
                        }
                    ],
                    'status'           : 'closed',
                    'priority'         : 'none',
                    'subscribed'       : false,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'Currently we have two new designs ready to ship.',
                            'time'    : 'now'
                        }
                    ],
                    'activities'       : []
                },
                {
                    'id'               : 'f6b9d7a9247e5d794a081929',
                    'name'             : 'Fixed footer',
                    'description'      : '',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '56027c1930450d8bf7b10758',
                        '26027s1930450d8bf7b10828'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c6'
                    ],
                    'attachments'      : [],
                    'status'           : 'open',
                    'priority'         : 'none',
                    'subscribed'       : true,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [],
                    'activities'       : []
                },
                {
                    'id'               : 'f6b9d7a9247e5d794a081930',
                    'name'             : 'Collapsable navigation',
                    'description'      : '',
                    'idAttachmentCover': '',
                    'idMembers'        : [],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c6'
                    ],
                    'attachments'      : [],
                    'status'           : 'in progress',
                    'priority'         : 'none',
                    'subscribed'       : false,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'I\'m not sure why we re-doing the navigation. The current collapsable navigation works flawlessly.',
                            'time'    : 'now'
                        }
                    ],
                    'activities'       : []
                },
                {
                    'id'               : '5603a2a3cab0c8300f6096b4',
                    'name'             : 'Mail app new layout',
                    'description'      : 'Current layout has lots of flaws in mobile. Outlook view should help with that.',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '26027s1930450d8bf7b10828',
                        '56027c1930450d8bf7b10758'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c6',
                        '26022e4129ad3a5sc28b36cd'
                    ],
                    'attachments'      : [],
                    'status'           : 'review',
                    'priority'         : 'none',
                    'subscribed'       : false,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [],
                    'activities'       : []
                },
                {
                    'id'               : '5637273da9b93bb84743a100',
                    'name'             : 'API recover and monitoring',
                    'description'      : 'We need a service to monitor and recover failed APIs.',
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '56027c1930450d8bf7b10758',
                        '26027s1930450d8bf7b10828'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36cd',
                        '26022e4129ad3a5sc28b36c6'
                    ],
                    'attachments'      : [],
                    'status'           : 'closed',
                    'priority'         : 'none',
                    'subscribed'       : true,
                    'checklists'       : [
                        {
                            'id'               : '6926.2b31d119e4a.889401e0ca7a0.13ad8ce2e569d.976e54e8b5d87.456afccd7e820.d6c77106a',
                            'name'             : 'API Monitoring',
                            'checkItemsChecked': 2,
                            'checkItems'       : [
                                {
                                    'name'   : 'Simple dashboard design',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Should be able to see different time periods on the same dashboard',
                                    'checked': true
                                },
                                {
                                    'name'   : 'Different colors for different clusters',
                                    'checked': true
                                }
                            ]
                        },
                        {
                            'id'               : '7c22.5261c7924387f.248e8b1d32205.003f7a9f501d1.1d48dcdbe8b23.8099dcc5f75a7.29a966196',
                            'name'             : 'API Recovery',
                            'checkItemsChecked': 1,
                            'checkItems'       : [
                                {
                                    'name'   : 'Warning notifications to all developers',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Immediate recovery options attached to the notifications',
                                    'checked': true
                                },
                                {
                                    'name'   : 'Backups every 6hours',
                                    'checked': false
                                }
                            ]
                        }
                    ],
                    'checkItems'       : 6,
                    'checkItemsChecked': 3,
                    'comments'         : [],
                    'activities'       : []
                }
            ],
            'backlog' : {
                'name'    : 'Backlog',
                'idCards' : [],
            }
        },
        {
            'id'      : '2',
            'name'    : 'ACME Backend Application',
            'reference': 'REF002',
            'settings': {
                'color'          : 'blue-grey',
                'subscribed'     : false,
                'coverImages'    : true
            },
            'lists'   : [
                {
                    'id'     : '56027cf5a2ca3839a5d36103',
                    'name'   : 'Designs',
                    'idCards': [
                        '5603a2a3cab0c8300f6096b3'
                    ]
                },
                {
                    'id'     : '56127cf2a2ca3539g7d36103',
                    'name'   : 'Development',
                    'idCards': [
                        '5637273da9b93bb84743a0f9'
                    ]
                }
            ],
            'cards'   : [
                {
                    'id'               : '5603a2a3cab0c8300f6096b3',
                    'name'             : 'Calendar App Design',
                    'description'      : '',
                    'idAttachmentCover': '56027cfcbe1b72ecf1fc452a',
                    'idMembers'        : [
                        '26027s1930450d8bf7b10828',
                        '56027c1930450d8bf7b10758'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36c3',
                        '26022e4129ad3a5sc28b36c6'
                    ],
                    'attachments'      : [
                        {
                            'id'  : '56027cfcbe1b72ecf1fc452a',
                            'name': 'calendar-app-design.jpg',
                            'src' : 'assets/images/cards/02-320x200.jpg',
                            'time': 'Nov 1 at 12:34PM',
                            'type': 'image'
                        },
                        {
                            'id'  : '67027cahbe3b52ecf2dc631c',
                            'url' : 'assets/images/cards/02-320x200.jpg',
                            'time': 'Nov 3 at 15:22AM',
                            'type': 'link'
                        }
                    ],
                    'status'           : 'in progress',
                    'priority'         : 'none',
                    'subscribed'       : true,
                    'checklists'       : [
                        {
                            'id'               : '63021cfdbe1x72wcf1fc451v',
                            'name'             : 'Checklist',
                            'checkItemsChecked': 1,
                            'checkItems'       : [
                                {
                                    'name'   : 'Implement a calendar library',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Replace event colors with Material Design colors',
                                    'checked': true
                                },
                                {
                                    'name'   : 'Replace icons with Material Design icons',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Use moment.js',
                                    'checked': false
                                }
                            ]
                        },
                        {
                            'name'             : 'Checklist 2',
                            'id'               : '74031cfdbe1x72wcz1dc166z',
                            'checkItemsChecked': 1,
                            'checkItems'       : [
                                {
                                    'name'   : 'Replace event colors with Material Design colors',
                                    'checked': true
                                },
                                {
                                    'name'   : 'Replace icons with Material Design icons',
                                    'checked': false
                                },
                                {
                                    'name'   : 'Use moment.js',
                                    'checked': false
                                }
                            ]
                        }
                    ],
                    'checkItems'       : 7,
                    'checkItemsChecked': 2,
                    'comments'         : [
                        {
                            'idMember': '56027c1930450d8bf7b10758',
                            'message' : 'We should be able to add moment.js without any problems',
                            'time'    : '12 mins. ago'
                        },
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'I added a link for a page that might help us deciding the colors',
                            'time'    : '30 mins. ago'
                        }
                    ],
                    'activities'       : [
                        {
                            'idMember': '56027c1930450d8bf7b10758',
                            'message' : 'added a comment',
                            'time'    : moment().subtract(25, 'minutes').toISOString() // 25 minutes ago
                        },
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'added a comment',
                            'time'    : moment().subtract(50, 'minutes').toISOString() // 50 minutes ago
                        },
                        {
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'attached a link',
                            'time'    : moment().subtract(3, 'hours').toISOString() // 3 hours ago
                        }
                    ]
                },
                {
                    'id'               : '5637273da9b93bb84743a0f9',
                    'name'             : 'Fix Splash Screen bugs',
                    'description'      : '',
                    'idAttachmentCover': '5603a2ae2bbd55bb2db57478',
                    'idMembers'        : [
                        '56027c1930450d8bf7b10758'
                    ],
                    'idLabels'         : [],
                    'attachments'      : [
                        {
                            'id'  : '5603a2ae2bbd55bb2db57478',
                            'name': 'mail-app-design.jpg',
                            'src' : 'assets/images/cards/01-320x200.jpg',
                            'time': 'Nov 1 at 12:34PM',
                            'type': 'image'
                        }
                    ],
                    'status'           : 'in progress',
                    'priority'         : 'none',
                    'subscribed'       : true,
                    'checklists'       : [],
                    'checkItems'       : 0,
                    'checkItemsChecked': 0,
                    'comments'         : [],
                    'activities'       : []
                }
            ]
        }
    ];
}
