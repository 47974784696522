import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Apps } from 'app/layout/common/apps/apps.types';

@Injectable({
    providedIn: 'root'
})
export class AppsService
{
    private _shortcuts: ReplaySubject<Apps[]> = new ReplaySubject<Apps[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for shortcuts
     */
    get shortcuts$(): Observable<Apps[]>
    {
        return this._shortcuts.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all messages
     */
    getAll(): Observable<Apps[]>
    {
        return this._httpClient.get<Apps[]>('api/common/apps').pipe(
            tap((apps) => {
                this._shortcuts.next(apps);
            })
        );
    }
}
