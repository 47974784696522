export const environment = {
    production      : true,
    url_api         : 'https://api.worcal.com/rest',
    url_cdn         : 'https://cdn.worcal.com',
    address_account : 'e-ambition',
    firebase_config : {
        apiKey            : 'AIzaSyDB8reX3oSetCvHQCFwkwGWU3HZ9Hqmrf4',
        authDomain        : 'api-project-239415642847.firebaseapp.com',
        databaseURL       : 'https://api-project-239415642847.firebaseio.com',
        projectId         : 'api-project-239415642847',
        storageBucket     : 'api-project-239415642847.appspot.com',
        messagingSenderId : '239415642847',
        appId             : '1:239415642847:web:243bbe466d4670b0cc376a'
    }
};
