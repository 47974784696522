import { Injectable } from '@angular/core';
import { assign, cloneDeep } from 'lodash-es';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import { apps as appsData } from 'app/mock-api/common/apps/data';

@Injectable({
    providedIn: 'root'
})
export class AppsMockApi
{
    private _apps: any = appsData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ apps - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/apps')
            .reply(() => [200, cloneDeep(this._apps)]);

        // -----------------------------------------------------------------------------------------------------
        // @ apps - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/common/apps')
            .reply(({request}) => {

                // Get the shortcut
                const newShortcut = cloneDeep(request.body.shortcut);

                // Generate a new GUID
                newShortcut.id = FuseMockApiUtils.guid();

                // Unshift the new shortcut
                this._apps.unshift(newShortcut);

                // Return the response
                return [200, newShortcut];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ apps - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/common/apps')
            .reply(({request}) => {

                // Get the id and shortcut
                const id = request.body.id;
                const shortcut = cloneDeep(request.body.shortcut);

                // Prepare the updated shortcut
                let updatedShortcut = null;

                // Find the shortcut and update it
                this._apps.forEach((item: any, index: number, apps: any[]) => {

                    if ( item.id === id )
                    {
                        // Update the shortcut
                        apps[index] = assign({}, apps[index], shortcut);

                        // Store the updated shortcut
                        updatedShortcut = apps[index];
                    }
                });

                // Return the response
                return [200, updatedShortcut];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ apps - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/common/apps')
            .reply(({request}) => {

                // Get the id
                const id = request.params.get('id');

                // Prepare the deleted shortcut
                let deletedShortcut = null;

                // Find the shortcut
                const index = this._apps.findIndex((item: any) => item.id === id);

                // Store the deleted shortcut
                deletedShortcut = cloneDeep(this._apps[index]);

                // Delete the shortcut
                this._apps.splice(index, 1);

                // Return the response
                return [200, deletedShortcut];
            });
    }
}
