import { InMemoryDbService } from 'angular-in-memory-web-api';

import { BoardFakeDb } from 'app/apps/board/utils/fake-db/board';

export class FakeDbService implements InMemoryDbService
{
    createDb(): any
    {
        return {
            'board-boards': BoardFakeDb.boards,
        };
    }
}
